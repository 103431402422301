module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cadiblack Concierge Services","short_name":"CadiblackConcierge","start_url":"/","background_color":"#000","theme_color":"#fff","display":"standalone","icon":"static/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c5330c72249b53a962442548b52d4cba"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
